@font-face {
  font-family: "Quicksand";
  src: url("./../public/Quicksand-VariableFont_wght.ttf");
}

@import url('./components/elements/PageScrolling.css');
@import url('./components/elements/ArticleBlocks.css');

body {
  margin: 0;
  padding: 0;
  font-family: 'Quicksand', Arial, sans-serif;
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow: scroll-y;
  flex-direction: column;
  min-height: 100vh;
  position: relative;
  background-color: #0F1A20;
  color: #E1F4F3;
}

.master-frame {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
  padding: 0;
  flex-grow: 1;
  background-color: #012D40;
}

@media (min-width: 768px) {
  .master-frame {
    width: 90%;
    height: auto;
    margin: auto;
    padding: 0;
  }
}

header {
  background: url('/public/banner_2.png'), linear-gradient(120deg, #004445 0%, #2C7873 50%, #6FB98F 100%);
  background-blend-mode: overlay;
  background-size: cover;
  width: 100%;
  height: 366px;
  /* Adjust this value to your preference */
}

.header-nav {
  display: flex;
  align-items: center;
  align-self: flex-end;
  background: linear-gradient(to right, rgba(0, 0, 0, 0) 1%, rgba(0, 0, 0, 0.9) 3%, rgba(0, 0, 0, 0.8) 100%);
}

.header-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}

.header-content-top,
.header-content-bottom {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  /* Add this line */
}

.header-content-bottom {
  justify-content: flex-end;
}

.header-foot-line {
  width: 100%;
  height: 2px;
  background: linear-gradient(90deg, #35524A 0%, #77966D 50%, #BFDCAE 100%);
}

.header-title,
.header-logo {
  display: flex;
  align-items: center;
  align-self: flex-start;
  margin: 20px;
}

.header-logo img {
  width: 100px;
}

.header-title h1 {
  margin-left: 10px;
  font-size: min(1.5em, 8vw);
  font-weight: 1000;
  width: max-content;
  text-align: center;
  background-color: rgb(255, 178, 62);
  background-image: linear-gradient(268.67deg, #004445 3.43%, #2C7873 55.54%, #6FB98F 99%);
  background-size: 100%;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  margin: 0px;
}

.header-menu {
  display: flex;
  align-items: flex-end;
  color: #F0F0F1;
  height: 100%;
}

.header-menu-item {
  cursor: pointer;
  font-weight: 800;
  height: 100%;
}

.header-menu-item a {
  display: block;
  height: 100%;
}

footer {
  background-color: #03030D;
  width: 100%;
  text-align: center;
}

.footer-head-line {
  width: 100%;
  height: 2px;
  background-color: #35524A;
}

main {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.icon {
  display: inline-block;
  margin-right: 5px;
}

a {
  color: #F0F0F1;
  text-decoration: none;
  font-size: 18px;
  /* Adjust this value to your preference */
  padding: 10px 10px;
  /* 10px padding to the top and bottom, 0 to the left and right */
  text-wrap: nowrap;
}

a:hover {
  text-decoration: underline;
}