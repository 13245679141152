.info-list, .info-list-horizontal {
    display: flex;
    flex: 3;
    flex-direction: column;
    padding: 0;
    margin: 50px;
    color: #2C7873; /* Green-blue color for text */
}

.info-list-horizontal {
    flex: 1;
    flex-wrap: wrap;
}

.info-list-item {
    display: flex;
    flex-direction: column;
    /* Aligns children in a vertical stack */
    align-items: flex-start;
    /* Aligns children to the start of the flex container */
    width: 100%;
    color: #6FB98F; /* Lighter green color for text */
    margin-top: 30px;
    text-align: justify;
    hyphens: auto;
}

.info-list-item>.header-line {
    width: 100%;
    height: 2px;
    background-color: #004445; /* Dark green-blue for header line */
    margin-bottom: 10px;
}

.info-list-item>.header-title {
    display: flex;
    flex-direction: row;
}

.info-list-item>.header-title>.title-text {
    display: flex;
    margin-left: 10px;
}

.info-list-item a {
    color: #35524A; /* Darker green for links */
}

.info-list-item>h2 {
    margin: 0;
    padding: 0;
    font-size: 1.2em;
    color: #E1F4F3; /* Light greenish text */
}

.info-list>h3 {
    margin: 0;
    padding: 0;
    font-size: 1em;
    color: #BFDCAE; /* Soft green for smaller headers */
}